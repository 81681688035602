import { formatObjectValues, objClone } from '../lib/object-utils'
import { hasAction } from './auth'

export const formatConfiguratorRequest = (data, isSearch) => {
  const p = objClone(data)
  const isAirCoolOrHot = p.ui.air.show.cool || p.ui.air.show.hot
  const isFluidCoolOrHot = p.ui.fluid.show.cool || p.ui.fluid.show.hot

  // HEAT EXCHANGERS
  Object.keys(p.heatExchangers).forEach(k => !p.ui.heatExchangers.show[k] && delete p.heatExchangers[k])

  // AIR
  if (isAirCoolOrHot) {
    delete p.air.inputTemperature
    delete p.air.relativeHumidity
  }
  if (!isAirCoolOrHot || !p.ui.air.show.hot) {
    delete p.air.inputTemperatureHot
    delete p.air.relativeHumidityHot
  }
  if (!isAirCoolOrHot || !p.ui.air.show.cool) {
    delete p.air.inputTemperatureCool
    delete p.air.relativeHumidityCool
  }
  formatObjectValues(p.air)

  // FLUID
  if (p.ui.fluid.show.gas || isFluidCoolOrHot) {
    delete p.fluid.inputTemperature
    delete p.fluid.outputTemperature
  } else {
    delete p.fluid.condensingTemperature
    delete p.fluid.gasTemperature
    delete p.fluid.subCoolingDeltaTemperature
  }
  if (!isFluidCoolOrHot || !p.ui.fluid.show.hot) {
    delete p.fluid.inputTemperatureHot
    delete p.fluid.outputTemperatureHot
  }
  if (!isFluidCoolOrHot || !p.ui.fluid.show.cool) {
    delete p.fluid.inputTemperatureCool
    delete p.fluid.outputTemperatureCool
  }
  formatObjectValues(p.fluid)

  // DUTY
  if (!p.ui.duty.show.waterFlow) {
    delete p.duty.waterFlow
  }

  formatObjectValues(p.duty)

  if (p.ui.duty.show.doubleCircuitOption && p.duty.doubleCircuitOption) {
    p.duty.secondCircuitCapacity = p.duty.circuitCapacity
  }

  // DIMENSIONS
  formatObjectValues(p.dimensions)

  // VENTILATION NOISE
  formatObjectValues(p.ventilationNoise)

  // VENTILATION POWER SOURCE
  if (p.ventilationPowerSource === '-') p.ventilationPowerSource = null

  // EFFICIENCY CLASS
  if (p.energyEfficiencyClass === '-') p.energyEfficiencyClass = null

  p.maxPowerConsumption = p.maxPowerConsumption
    ? parseInt(p.maxPowerConsumption, 10)
    : null

  // CLEANUP
  if (p.productConfig) {
    p.productId = p.productConfig.id
    if (p.ui.searchMode === 'byname') {
      delete p.duty.circuitCapacity
    } else {
      delete p.fanPercentage
    }
    delete p.family
    delete p.productConfig
    delete p.heatExchangers
    delete p.dimensions
    delete p.ventilationNoise.soundPowerLw
    delete p.ventilationNoise.soundPressureLp
    delete p.ventilationTech
    delete p.ventilationPowerSource
    delete p.maxPowerConsumption
    delete p.energyEfficiencyClass
  } else {
    delete p.fanPercentage
  }
  if (!p.tubeSeriesNumber || p.tubeSeriesNumber === '-' || (p.ui.searchMode === 'bytype' && !hasAction('configurator.tube_series_number'))) {
    delete p.tubeSeriesNumber
  } else {
    p.tubeSeriesNumber = parseInt(p.tubeSeriesNumber, 10)
    delete p.duty.maxLiquidPressureDrop
  }
  if (!p.finSpacing || p.finSpacing === '-' || !hasAction('configurator.fin_spacing')) {
    delete p.finSpacing
  } else {
    p.finSpacing = parseFloat(p.finSpacing)
  }
  if (isSearch || !p.finThickness) {
    delete p.finThickness
  } else {
    p.finThickness = parseFloat(p.finThickness)
  }
  if (p.productType !== 'lhp') delete p.priority
  if (p.productType !== 'lc' || p.ui.duty.disableSurfaceMargin || !hasAction('configurator.surface_margin')) delete p.duty.surfaceMargin
  delete p.ui
  delete p.errors
  delete p.version
  delete p.key
  delete p.previouslySelectedProductType

  return p
}
