export const unitsOfMeasurement = {
  SI: {
    altitude: 'm',
    area: 'm²',
    areaSmall: 'dm²',
    current: 'A',
    flow: 'm³/h',
    flowAlt2: 'l/h',
    flowAlt3: 'm³/h',
    flowAlt: 'kg/h',
    foulingFactor: 'm² K/kW',
    length: 'm',
    lengthSmall: 'mm',
    lengthSmallAlt: 'mm',
    lengthSmaller: 'mm',
    finSpacing: 'mm',
    percentage: '%',
    percentageAlt: '%',
    power: 'kW',
    powerConsumption: 'kW',
    powerConsumptionOverTime: 'kWh',
    powerConsumptionSmall: 'W',
    pressure: 'Pa',
    pressureAlt: 'Pa',
    pressureDrop: 'kPa',
    pressureDropInKelvin: 'K',
    soundPressureLevel: 'dB(A)',
    speed: 'm/s',
    temperature: '°C',
    temperatureAlt: 'K',
    volume: 'm³',
    volumeSmall: 'dm³',
    weight: 'kg'
  },
  SIB: {
    altitude: 'ft',
    area: 'ft²',
    areaSmall: 'ft²',
    current: 'A',
    flow: 'GPM',
    flowAlt2: 'GPM',
    flowAlt3: 'CFM',
    flowAlt: 'lb/h',
    foulingFactor: 'm² K/kW', // not converted yet
    length: 'ft',
    lengthSmall: 'ft',
    lengthSmallAlt: 'FPI',
    lengthSmaller: 'FPI',
    finSpacing: 'FPI',
    percentage: '%',
    percentageAlt: '%',
    power: 'BTU/h',
    powerConsumption: 'kW',
    powerConsumptionOverTime: 'kWh',
    powerConsumptionSmall: 'W',
    pressure: 'psi',
    pressureAlt: 'psi',
    pressureDrop: 'ftH2O',
    pressureDropInKelvin: 'K',
    soundPressureLevel: 'dB(A)',
    speed: 'ft/min',
    temperature: '°F',
    temperatureAlt: 'K',
    volume: 'ft³',
    volumeSmall: 'ft³',
    weight: 'lbs'
  }
}

export const unitsOfMeasurementDecimals = {
  SI: {
    altitude: 0,
    area: 1,
    areaSmall: 1,
    current: 1,
    flow: 1,
    flowAlt2: 1,
    flowAlt3: 0,
    flowAlt: 1,
    foulingFactor: 3,
    length: 1,
    lengthSmall: 0,
    lengthSmallAlt: 1,
    lengthSmaller: 2,
    finSpacing: 1,
    percentage: 0,
    percentageAlt: 1,
    power: 1,
    powerConsumption: 1,
    powerConsumptionOverTime: 1,
    powerConsumptionSmall: 0,
    pressure: 1,
    pressureAlt: 0,
    pressureDrop: 1,
    pressureDropInKelvin: 1,
    soundPressureLevel: 0,
    speed: 1,
    temperature: 1,
    temperatureAlt: 1,
    volume: 1,
    volumeSmall: 1,
    weight: 1
  },
  SIB: {
    altitude: 0,
    area: 2,
    areaSmall: 2,
    current: 1,
    flow: 1,
    flowAlt2: 1,
    flowAlt3: 0,
    flowAlt: 1,
    foulingFactor: 3, // not converted yet
    length: 1,
    lengthSmall: 2,
    lengthSmallAlt: 2,
    lengthSmaller: 2,
    finSpacing: 1,
    percentage: 0,
    percentageAlt: 1,
    power: 0,
    powerConsumption: 1,
    powerConsumptionOverTime: 1,
    powerConsumptionSmall: 0,
    pressure: 2,
    pressureAlt: 1,
    pressureDrop: 2,
    pressureDropInKelvin: 1,
    soundPressureLevel: 0,
    speed: 2,
    temperature: 1,
    temperatureAlt: 1,
    volume: 2,
    volumeSmall: 2,
    weight: 2
  }
}

export const conversions = {
  metersToFeet: x => x * 3.2808399,
  millimetersToFinsPerInch: x => 1 / (0.0393701 * x)
}
